* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.services,
.products,
.sign-up, 
.engaging,
.linkable,
.fast,
.work-offline,
.installable,
.discoverable,
.reliable,
.secure,
.responsive,
.integrated,
.cross-platform,
.native-features
{
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  background-image: url('/images/internet.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: black;
  font-size: 100px;
}

.products {
  background-image: url('/images/internet.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: black;
  font-size: 100px;
}

.sign-up {
  background-image: url('/images/internet.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: black;
  font-size: 100px;
}

.engaging {
  background-image: url('/images/internet.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: black;
  font-size: 100px;
}

.linkable {
  background-image: url('/images/internet.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: black;
  font-size: 100px;
}

.fast {
  background-image: url('/images/internet.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: black;
  font-size: 100px;
}

.work-offline {
  background-image: url('/images/internet.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: black;
  font-size: 100px;
}

.installable {
  background-image: url('/images/internet.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: black;
  font-size: 100px;
}

.discoverable {
  background-image: url('/images/internet.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: black;
  font-size: 100px;
}

.reliable {
  background-image: url('/images/internet.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: black;
  font-size: 100px;
}

.secure {
  background-image: url('/images/internet.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: black;
  font-size: 100px;
}

.responsive {
  background-image: url('/images/internet.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: black;
  font-size: 100px;
}

.integrated {
  background-image: url('/images/internet.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: black;
  font-size: 100px;
}

.cross-platform {
  background-image: url('/images/internet.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: black;
  font-size: 100px;
}

.native-features {
  background-image: url('/images/internet.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: black;
  font-size: 100px;
}
